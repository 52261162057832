import type { ICenterBase } from "@app-rest/entities.interfaces";
import useAppStore from "@app-store/app";
import { CartModuleInit } from "@app-store/cart";
import useCompanyStore from "@app-store/company";
import { ReservationModuleInit } from "@app-store/reservation";
import useSystemStore from "@app-store/system";

import { timeout } from "./func";

type SystemCompanyPair = Pick<ICenterBase, "Id" | "CompanyId">

function checkEnvChannelRedirect() {
	const AppStore = useAppStore();
	const SystemStore = useSystemStore();

	const currentEnv = AppStore.env.toLowerCase();
	const currentChannel = AppStore.channel.toLowerCase();
	const systemChannel = SystemStore.info?.Channel.toLowerCase() ?? "stable";

	if (currentEnv !== "development" && currentEnv !== "local" && systemChannel !== currentChannel) {
		const site = systemChannel === "beta" ? AppStore.websiteBeta : AppStore.website;
		const targetWebsite = new URL(site);
		const redirectUrl = new URL(location.href);
		redirectUrl.host = targetWebsite.host;
		location.href = redirectUrl.toString();
	}
}

type EnsureSystemCompanyPairResult = {
	success: false,
	details: string
} | { success: true }
export async function ensureSystemCompanyPair(pair: SystemCompanyPair, skipTheme = false): Promise<EnsureSystemCompanyPairResult> {
	const SystemStore = useSystemStore();
	if (SystemStore.Id === pair.Id) return Promise.resolve({ success: true });

	document.title = "My BowlingPassport";
	SystemStore.clearData();
	await Promise.all([
		SystemStore.ensureInfo(pair.Id),
		SystemStore.ensureFunctionalities(pair.Id)
	]);

	if (!SystemStore.Id)
		return Promise.resolve({ success: false, details: "System not found or unauthorized" });

	if (SystemStore.CompanyId !== pair.CompanyId) {
		return Promise.resolve({
			success: false,
			details: "System and company mismatch"
		});
	}

	checkEnvChannelRedirect();

	if (!skipTheme)
		useAppStore().setThemeRequireLoad();

	document.title = SystemStore.Name;

	await Promise.all([
		ReservationModuleInit,
		CartModuleInit
	]);
	return Promise.resolve({ success: true });
}
export async function ensureWebsiteData() {
	const SystemStore = useSystemStore();
	const CompanyStore = useCompanyStore();
	const AppStore = useAppStore();
	await Promise.all([
		SystemStore.ensureConfigurations(),
		SystemStore.ensureTemplateCommonParts(),
		SystemStore.syncTemplateNavigationBar(),
		SystemStore.ensureTemplateHomePage(),
		SystemStore.ensurePlayerTypes(),
		CompanyStore.ensureLocations(),
		CompanyStore.ensurePrivacyData(CompanyStore.Id),
		CompanyStore.ensurePolicies(),
		SystemStore.syncTerms(),
		AppStore.ensurePWAContext({
			companyId: CompanyStore.Id.toString(),
			systemId: SystemStore.Id.toString()
		})
	]);
}
export function waitForSystem() {
	const SystemStore = useSystemStore();
	return new Promise<void>(resolve => {
		const checkIt = async() => {
			if (SystemStore.info && SystemStore.Id)
				return resolve();

			await timeout(500);
			checkIt();
		};
		checkIt();
	});
}

export async function resolveSystem() {
	const CompanyStore = useCompanyStore();
	await CompanyStore.ensureLocations();
	const systems = CompanyStore.plainLocations.length && CompanyStore.plainLocations[0].Id > 1
		? CompanyStore.plainLocations
		: [];

	return systems && systems[0] ? systems[0].Id : undefined;
}
