import type { IPayAndConfirmResultPayload, IPaypalCompletionPayload } from "@app-store/reservation";
import useSystemStore from "@app-store/system";
import { dateTimeKey } from "@app-utilities/dates";

import PortalRestClientFactory, { IResponseError } from ".";
import {
	type IFoodBeverage, type IFoodBeverageCategory, type IFootwearResponse, type IItemModifierGroupBE, type IOffer,
	type IOfferQueryCategoryId, type IOfferQueryDate, type IOfferQueryPagination,
	type IOfferQueryPlayers, type IOffersQuery, type IOffersQuerySystem, type IPackageModifierGroupBE, type IReservationBowlerOptions, type IReservationConfirmData, type IReservationConfirmResponse, type IReservationExtra, type IReservationGuestConfirmData, type IReservationLifetime, 	type IReservationOptions,
	type IReservationRequest, type IReservationStatusResponse, type IShoesCategoryData, type IWebOfferOptions, type ReservationConfirmError, type ReservationDeleteError, type ReservationError, type ReservationLifetimeError, type ReservationOffersError, type ReservationStatus, type ReservationStatusErrorCodes,
	ReservationStatusErrors,
	type ReservationTimeoutError
} from "./reservations.interfaces";

export default class ReservationRestFactory extends PortalRestClientFactory {
	constructor(token?: string) {
		super();
		if (token) {
			const headers = this.options.get("headers") ?? new Headers();
			headers.set("X-SessionToken", token);
			this.options.set("headers", headers);
		}
		this.options.set("queryParamsIncludeEmpty", false);
	}

	buildQueryParams(query: Partial<IOffersQuery>
			& Partial<IOfferQueryPagination>
			& Partial<IOfferQueryDate>
			& Partial<IOfferQueryPlayers>
			& Partial<IOfferQueryCategoryId>) {
		if (!query.page) query.page = 1;
		if (!query.itemsPerPage) query.itemsPerPage = 50;
		if (query.datetime instanceof Date) {
			if (isNaN(+query.datetime))
				query.datetime = "";
			else query.datetime = dateTimeKey(query.datetime);
		}
		if (typeof query.players !== "string" && typeof query.players !== "undefined") {
			query.players = query.players
				.map(p => `${p.Id}-${p.Quantity}`)
				.join(",");
		}

		return query;
	}

	offers(query: IOffersQuerySystem & IOfferQueryDate & IOfferQueryPlayers) {
		return this.get<IOffer[], IResponseError<ReservationOffersError>>(
			`/centers/${query.systemId}/offers-availability`, {
				query: this.buildQueryParams(query),
				throwExcluding: [
					error => {
						const errorCode = error?.data?.Error?.Code;

						return error.statusCode === 409 && errorCode === "QDateTimeNotValid";
					}
				]
			});
	}

	foodBeverageCategories(query: IOffersQuery & IOfferQueryDate) {
		return this.get<IFoodBeverageCategory[]>(
			`/centers/${query.systemId}/offers/${query.offerId}/food-beverage-categories`, {
				query: this.buildQueryParams(query),
				cacheInMemory: true
			});
	}

	foodBeverage(query: IOffersQuerySystem & IOfferQueryDate & IOfferQueryCategoryId & Partial<IOfferQueryPagination>) {
		return this.get<IFoodBeverage[]>(`/centers/${query.systemId}/offers/food-beverage`, {
			query: this.buildQueryParams(query)
		});
	}

	getItemFoodBeverageModifiers(systemId: number, pricekeyId: number) {
		return this.get<IItemModifierGroupBE>(`/centers/${systemId}/Items/${pricekeyId}/Modifiers`);
	}

	getPackageFoodBeverageModifiers(systemId: number, pricekeyId: number) {
		return this.get<IPackageModifierGroupBE>(`/centers/${systemId}/Packages/${pricekeyId}/Modifiers`);
	}

	footwears(query: IOffersQuery & IOfferQueryDate) {
		return this.get<IFootwearResponse>(`/centers/${query.systemId}/offers/${query.offerId}/shoes-socks-offer`, {
			query: this.buildQueryParams(query)
		});
	}

	getShoesCategory(systemId: number) {
		return this.get<IShoesCategoryData>(`/centers/${systemId}/ShoesSize`);
	}

	extras(query: IOffersQuery & IOfferQueryDate & Partial<IOfferQueryPagination>) {
		return this.get<IReservationExtra[]>(`/centers/${query.systemId}/offers/extras`, {
			query: this.buildQueryParams(query)
		});
	}

	reservationCreate(systemId: number, data: IReservationRequest) {
		return this.post<IReservationLifetime, IResponseError<ReservationLifetimeError>>(
			`/centers/${systemId}/reservations/temporary-request/book-for-later`,
			{
				body: {
					...data,
					DateFrom: dateTimeKey(data.DateFrom)
				},
				throwExcluding: [
					(error) => {
						const errorCode = error?.data?.Error?.Code ?? "";

						return error.statusCode === 409 && ["QResourceNotAvailable", "UnlimitedConfigConflict", "BookingConflict"].includes(errorCode);
						// FIXME: Gestire il 503 (Conqueror offline), attendere user story dedicata come da accordi con Analisi
					}
				]
			}
		);
	}

	reservationStatus(systemId: number, reservationKey: string) {
		return this.get<ReservationStatus, IResponseError<ReservationTimeoutError>>(`/centers/${systemId}/reservations/${reservationKey}/status`, {
			throwExcluding: [
				error => {
					const errorCode = error?.data?.Error?.Code ?? "";

					if (error.statusCode === 409)
						return ["ReservationTimedOut"].includes(errorCode);

					return false;
				}
			]
		});
	}

	reservationRenew(systemId: number, reservationKey: string) {
		return this.patch<IReservationLifetime, IResponseError<ReservationLifetimeError>>(`/centers/${systemId}/reservations/${reservationKey}/lifetime`, {
			throwExcluding: [
				error => {
					const errorCode = error?.data?.Error?.Code ?? "";

					if (!useSystemStore().isAsyncPaymentsFlowEnabled && error.statusCode === 401)
						return true;
					else if (error.statusCode === 404)
						return errorCode === "ReservationNotFound";
					else if (error.statusCode === 409)
						return ["ReservationAlreadyConfirmed", "ReservationConflict", "ReservationNotTemporary"].includes(errorCode);

					return false;
				}
			]
		});
	}

	reservationConfirm(systemId: number, reservationKey: string, data: IReservationConfirmData) {
		return this.post<IReservationConfirmResponse, IResponseError<ReservationConfirmError>>(`/centers/${systemId}/reservations/${reservationKey}/confirm`, {
			body: data,
			throwExcluding: [
				error => {
					const errorCode = error?.data?.Error?.Code ?? "";

					switch (error.statusCode) {
						case 404:
							return ["ModifierNotFound", "ReservationExpired", "ReservationNotFound"].includes(errorCode);
						case 409:
							return ["ModifierChanged", "PriceAvailabilityChanged", "PriceValueChanged", "ReservationAlreadyConfirmed", "ReservationConflict", "ReservationNotTemporary"].includes(errorCode);
						case 502:
							return ["PaymentException", "PaymentProviderNotConfigured"].includes(errorCode);
						default:
							return false;
					}
				}
			]
		});
	}

	reservationGuestConfirm(systemId: number, reservationKey: string, data: IReservationGuestConfirmData) {
		return this.post<IReservationConfirmResponse, IResponseError<ReservationConfirmError>>(`/centers/${systemId}/reservations/${reservationKey}/guest/confirm`, {
			body: data,
			throwExcluding: [
				error => {
					const errorCode = error?.data?.Error?.Code ?? "";

					switch (error.statusCode) {
						case 404:
							return ["ModifierNotFound", "ReservationExpired", "ReservationNotFound"].includes(errorCode);
						case 409:
							return ["ModifierChanged", "PriceAvailabilityChanged", "PriceValueChanged", "ReservationAlreadyConfirmed", "ReservationConflict", "ReservationNotTemporary"].includes(errorCode);
						case 502:
							return ["PaymentException", "PaymentProviderNotConfigured"].includes(errorCode);
						default:
							return false;
					}
				}
			]
		});
	}

	reservationConfirmPayment(systemId: number, reservationKey: string, data: IPaypalCompletionPayload | IPayAndConfirmResultPayload["providerData"]) {
		return this.put<null, IResponseError<ReservationError>>(`/centers/${systemId}/reservations/${reservationKey}/payment-confirm`, {
			body: { QueryParams: data },
			throwExcluding: [
				error => {
					const errorCode = error?.data?.Error?.Code ?? "";

					if (!useSystemStore().isAsyncPaymentsFlowEnabled) {
						switch (error.statusCode) {
							case 402:
							case 404:
							case 406:
							case 422:
							case 502:
								return true;
							case 409:
								return ["ReservationAlreadyConfirmed", "ReservationConflict", "ReservationNotTemporary"].includes(errorCode);
							default:
								return false;
						}
					} else {
						switch (error.statusCode) {
							case 402:
								return errorCode === "PaymentCanceled";
							case 404:
								return ["OperationIdNotFound", "ReservationExpired"].includes(errorCode);
							case 409:
								return errorCode === "ReservationConflict";
							default:
								return false;
						}
					}
				}
			]
		});
	}

	rollback(systemId: number, reservationKey: string) {
		return this.delete<null, IResponseError<ReservationError>>(`/centers/${systemId}/reservations/${reservationKey}/rollback`, {
			throwExcluding: [
				error => {
					const errorCode = error?.data?.Error?.Code ?? "";
					switch (error.statusCode) {
						case 409:
							return errorCode === "ReservationConflict";
						default:
							return false;
					}
				}
			]
		});
	}

	reservationSetEndFlow(systemId: number, reservationKey: string) {
		return this.patch(`/centers/${systemId}/reservations/${reservationKey}/SetEndFlow`);
	}

	reservationDelete(systemId: number, reservationKey: string) {
		return this.delete<any, IResponseError<ReservationDeleteError>>(`/centers/${systemId}/reservations/${reservationKey}`, {
			throwExcluding: [
				error => {
					const errorCode = error?.data?.Error?.Code;

					if (!useSystemStore().isAsyncPaymentsFlowEnabled && error.statusCode === 404)
						return true;
					else if (error.statusCode === 409)
						return errorCode === "PaymentInitiated" || errorCode === "ReservationConflict";
					return false;
				}
			]
		});
	}

	recap(systemId: number, reservationKey: string) {
		return this.get<Blob>(`/centers/${systemId}/reservations/${reservationKey}/recap`, {
			responseType: "blob"
		});
	}

	existsRecap(systemId: number, reservationKey: string) {
		return this.get(`/centers/${systemId}/reservations/${reservationKey}/exists-recap`, {
			throwExcluding: [
				{ statusCode: 204 }
			]
		});
	}

	getReservationOptions(systemId: number) {
		return this.get<IReservationOptions>(`/centers/${systemId}/ReservationOptions`);
	}

	getWebOfferOptions(systemId: number, offerId: number) {
		return this.get<IWebOfferOptions>(`/centers/${systemId}/weboffers/${offerId}/options`);
	}

	getReservationStatus(systemId: number, reservationKey: string, operationId: string) {
		return this.get<IReservationStatusResponse, IResponseError<ReservationStatusErrorCodes>>(`/centers/${systemId}/reservations/${reservationKey}/status/${operationId}`, {
			throwExcluding: [error => {
				return ((error.statusCode === 404)
					&& typeof error.data?.Error?.Code === "string"
					&& ReservationStatusErrors.includes(error.data.Error.Code));
			}]
		});
	}

	saveBowlersOptions(systemId: number, reservationKey: string, Players: IReservationBowlerOptions[]) {
		return this.patch<IReservationBowlerOptions, IResponseError>(`/centers/${systemId}/reservations/${reservationKey}/players`, {
			body: {
				Players
			}
		});
	}
}
