import usei18n from "@app-i18n/index";
import { IResponseError } from "@app-rest/index";
import ReservationRestFactory from "@app-rest/reservations";
import type { IFoodBeverage, IFoodBeverageCategory, IFootwear, IMandatoryPage, IModifierGroupBE, IOfferQueryCategoryId, IOfferQueryDate, IOfferQueryPagination, IOfferSelected, IOffersQuery, IOffersQuerySystem, IReservationBowlerOptions, IReservationConfirmData, IReservationConfirmResponse, IReservationData, IReservationExtra, IShoesCategory, IWebOfferOptions, PaymentStatus, ReservationConfirmError, ReservationStatus, ReservationViewName } from "@app-rest/reservations.interfaces";
import { type IOpeningDate, type IPlayersQuantity } from "@app-rest/systems.interfaces";
import { extrasPage, foodBeveragePage, footwearsPage } from "@app-router/routes/reservations";
import useAppStore from "@app-store/app";
import useSystemStore from "@app-store/system";
import useUserStore, { UserModuleInit } from "@app-store/user";
import { EventAI, trackUserEvent } from "@app-utilities/app-insights";
import type { MandatoryPage } from "@app-utilities/const";
import { dateKey, format, stringToDateUTC } from "@app-utilities/dates";
import { getPaymentPopup } from "@app-utilities/popup";
import { clearFluxPersistence, persistenceKey } from "@app-utilities/reservation-flux";
import usePayments, { getPaymentsAppUrl, PaymentError, PaymentProvider, providerRendersAs, setup as setupPaymentsEnv } from "@qamf/payments";
import { addMinutes } from "date-fns";
import type { IResponse } from "scarlett/lib/interfaces";
import { Action, Module, Mutation, VuexModule } from "vuex-class-modules";

import store from ".";
import useCartStore, { IModifierGroup } from "./cart";
import { getMutationContext, IBaseContext, IPersistentModule, resolveMutationContext, usePersistentModule } from "./persistent.plugin";

export type IPayAndConfirmResultPayload = {
	providerReplied: boolean,
	providerData: {[k: string]: string},
}

export interface IReservationConfirmWithoutPaymentResult extends IResponse<IReservationConfirmResponse, IResponseError<ReservationConfirmError>> {
	paymentHappened: false,
}
export interface IReservationConfirmWithPaymentResult extends IPayAndConfirmResultPayload {
	paymentHappened: true,
}

export type IReservationConfirmResult = IReservationConfirmWithoutPaymentResult | IReservationConfirmWithPaymentResult

interface IFoodBeveragesMapSetter {
	CategoryId: number,
	Items: IFoodBeverage[]
}
interface IFoodBeveragesItemsMapSetter {
	CategoryId: number,
	Loading: boolean
}

type TransactionIdentifiers = {
	OperationId: string,
	OrderId: string,
}

export interface IPaypalCompletionPayload {
	OperationId: string;
	OrderId: string;
	Checkout: "Cancel" | "Complete";
}

@Module class ReservationStoreFactory extends VuexModule implements IPersistentModule {
	restClient: ReservationRestFactory | null = null;
	loadingOffers = false;
	loadingFoodBeverageItems = new Map<number, boolean>();
	loadingFoodBeverageCategories = false;
	loadingFootwears = false;
	loadingExtras = false;
	loadingReservationApi = false;
	loadingConfirmPaymentApi = false;
	openingDayCached: IOpeningDate | null = null;
	formPickedDatetime: Date | null = null;
	pickedDatetime: Date | null = null;
	bowlers: IPlayersQuantity[] = [];
	bowlersTotal = 0;
	offer: IOfferSelected | null = null;
	options: IWebOfferOptions | null = null;
	foodBeverageCategories: IFoodBeverageCategory[] | null = [];
	foodBeverages = new Map<number, IFoodBeverage[]>();
	foodBeverageModifierGroups = new Map<number, IModifierGroup[]>();
	shoes: IFootwear[] | null = [];
	shoesCategory: IShoesCategory[] | null = [];
	shoesTotal = 0;
	socks: IFootwear[] | null = [];
	extras: IReservationExtra[] | null = [];
	operationId: string | null = null;
	reservationKey: string | null = null;
	reservationToken: string | null = null;
	reservationKeyExpire: Date | null = null;
	reservationStatus: ReservationStatus | null = null;
	reservationNeedPayment: boolean | null = null;
	paymentStatus: PaymentStatus | null = null;
	latestView: ReservationViewName | null = null;
	players: IReservationBowlerOptions[] = [];
	persistenceInitialized = false;
	mandatoryPages: IMandatoryPage[] | null = null;
	get openingDatetimeOpen() {
		if (!this.openingDayCached) return;
		const d = this.openingDayCached;
		return d.StartBookingTime ? stringToDateUTC(d.StartBookingTime) : undefined;
	}

	get openingDatetimeClose() {
		if (!this.openingDayCached) return;
		const d = this.openingDayCached;
		return d.EndBookingTime ? stringToDateUTC(d.EndBookingTime) : undefined;
	}

	get systemId() {
		return useSystemStore().Id;
	}

	get data(): IReservationData {
		return {
			systemId: this.systemId,
			offer: this.offer,
			pickedDatetime: this.pickedDatetime,
			bowlers: this.bowlers,
			bowlersTotal: this.bowlersTotal,
			shoesTotal: this.shoesTotal,
			reservationKey: this.reservationKey,
			reservationToken: this.reservationToken,
			reservationKeyExpire: this.reservationKeyExpire,
			reservationStatus: this.reservationStatus,
			reservationNeedPayment: this.reservationNeedPayment,
			paymentStatus: this.paymentStatus,
			latestView: this.latestView,
			mandatoryPages: this.mandatoryPages
		};
	}

	get hasReservationFormData() {
		return Boolean(this.systemId && this.pickedDatetime && this.bowlersTotal);
	}

	get summary() {
		const key = (this.bowlersTotal === 1 ? "label_bowler" : "label_bowlers");
		const date = this.pickedDatetime ? format(this.pickedDatetime, "P") : "";
		const time = this.pickedDatetime ? format(this.pickedDatetime, "p") : "";
		return this.hasReservationFormData && this.offer
			? `${this.bowlersTotal} ${usei18n().translateKey(key)} on ${date} at ${time}`
			: "";
	}

	get isReservationConfirmed() {
		return this.reservationStatus === "CONFIRMED";
	}

	get isReservationTemporary() {
		return this.reservationStatus === "TEMPORARY";
	}

	get isPaymentCompleted() {
		return this.paymentStatus === "COMPLETED";
	}

	get allMandatoryPagesVisited() {
		if (!this.mandatoryPages) return false;

		const notVisitedViews = this.mandatoryPages.filter(el => !el.visited);

		if (notVisitedViews.length > 0) return false;

		return true;
	}

	@Mutation setData(data: IReservationData) {
		Object.keys(data).forEach(key => {
			this[key] = data[key];
		});
	}

	@Mutation clearData() {
		this.openingDayCached = null;
		this.formPickedDatetime = null;
		this.pickedDatetime = null;
		this.bowlers = [];
		this.bowlersTotal = 0;
		this.offer = null;
		this.options = null;
		this.foodBeverageCategories = [];
		this.foodBeverages.clear();
		this.foodBeverageModifierGroups.clear();
		this.shoes = [];
		this.shoesCategory = [];
		this.shoesTotal = 0;
		this.socks = [];
		this.extras = [];
		this.operationId = null;
		this.reservationKey = null;
		this.reservationToken = null;
		this.reservationKeyExpire = null;
		this.reservationStatus = null;
		this.reservationNeedPayment = null;
		this.paymentStatus = null;
		this.latestView = null;
		this.players = [];
		this.mandatoryPages = null;
	}

	@Mutation clearReservation() {
		this.offer = null;
		this.options = null;
		this.operationId = null;
		this.reservationKey = null;
		this.reservationToken = null;
		this.reservationKeyExpire = null;
		this.reservationStatus = null;
		this.reservationNeedPayment = null;
		this.paymentStatus = null;
		this.mandatoryPages = null;
	}

	@Mutation setPersistenceReady() {
		this.persistenceInitialized = true;
	}

	@Mutation setLatestView(view: ReservationViewName | null) {
		this.latestView = view;
	}

	@Mutation setReservationToken(token: string | null) {
		if (this.reservationToken === token) return;

		this.reservationToken = token;
		this.restClient = new ReservationRestFactory(token ?? undefined);
	}

	@Mutation cacheOpeningDay(date: Date | null) {
		if (!date) {
			this.openingDayCached = null;
			return;
		}
		const datestring = dateKey(date);
		const cached = useSystemStore().getOpeningDay(datestring);
		this.openingDayCached = cached ?? null;
	}

	@Mutation setBowlers(bowlers: IPlayersQuantity[]) {
		this.bowlers = bowlers;
		this.bowlersTotal = bowlers.reduce((acc, val) => { return acc + val.Quantity }, 0);
	}

	@Mutation setFormPickedDatetime(datetime: Date | null) {
		this.formPickedDatetime = datetime;
	}

	@Mutation setPickedDatetime(datetime: Date) {
		this.pickedDatetime = datetime;
	}

	@Mutation setLoadingOffers(state: boolean) {
		this.loadingOffers = state;
	}

	@Mutation setLoadingFoodBeverageCategories(state: boolean) {
		this.loadingFoodBeverageCategories = state;
	}

	@Mutation setLoadingFoodBeverageItems(state: IFoodBeveragesItemsMapSetter) {
		this.loadingFoodBeverageItems.set(state.CategoryId, state.Loading);
	}

	@Mutation setLoadingFootwears(state: boolean) {
		this.loadingFootwears = state;
	}

	@Mutation setLoadingExtras(state: boolean) {
		this.loadingExtras = state;
	}

	@Mutation setLoadingReservationApi(state: boolean) {
		this.loadingReservationApi = state;
	}

	@Mutation setLoadingConfirmPaymentApi(state: boolean) {
		this.loadingConfirmPaymentApi = state;
	}

	@Mutation setSelectedOffer(offer: IOfferSelected) {
		this.offer = offer;
		this.pickedDatetime = offer.Datetime;
		this.reservationStatus = null;
		this.options = null;
	}

	@Mutation setOptions(options: IWebOfferOptions | null) {
		this.options = options;
	}

	@Mutation setFoodBeverageCategories(foodBeverageCats: IFoodBeverageCategory[] | null) {
		this.foodBeverageCategories = foodBeverageCats;
	}

	@Mutation setFoodBeverage(foodBeverage: IFoodBeveragesMapSetter) {
		this.foodBeverages.set(foodBeverage.CategoryId, foodBeverage.Items);
	}

	@Mutation setShoes(shoes: IFootwear[] | null) {
		this.shoes = shoes;
	}

	@Mutation setShoesCategory(categories: IShoesCategory[] | null) {
		this.shoesCategory = categories;
	}

	@Mutation setShoesTotal(total: number) {
		this.shoesTotal = total;
	}

	@Mutation setSocks(socks: IFootwear[] | null) {
		this.socks = socks;
	}

	@Mutation setExtras(extras: IReservationExtra[] | null) {
		this.extras = extras;
	}

	@Mutation setPlayers(players: IReservationBowlerOptions[] | []) {
		this.players = players ?? [];
	}

	@Mutation setOperationId(id: string | null) {
		this.operationId = id;
	}

	@Mutation setReservationKey(key: string | null) {
		this.reservationKey = key;
		if (key)
			useUserStore().reservationsHistoryAdd(key);
	}

	@Mutation updateReservationKeyExpire(minutes = 10) {
		const expire = addMinutes(new Date(), minutes);
		this.reservationKeyExpire = expire;
	}

	@Mutation setReservationStatus(status: ReservationStatus | null) {
		this.reservationStatus = status;
		if (status === "CONFIRMED" && this.reservationKey) {
			useUserStore().reservationsHistoryRemove(this.reservationKey);
			clearFluxPersistence();
		}
	}

	@Mutation setReservationNeedPayment(status: boolean | null) {
		this.reservationNeedPayment = status;
	}

	@Mutation setPaymentStatus(status: PaymentStatus | null) {
		this.paymentStatus = status;
	}

	@Mutation setMandatoryPages(pages: IMandatoryPage[] | null) {
		this.mandatoryPages = pages;
	}

	@Mutation setMandatoryPageVisited(pageName: MandatoryPage) {
		if (!this.mandatoryPages) return;

		const pageToSetVisitedIndex = this.mandatoryPages.findIndex(el => el.name === pageName);

		if (pageToSetVisitedIndex === -1) return;

		this.mandatoryPages[pageToSetVisitedIndex].visited = true;
	}

	@Mutation setRestClient(restClient: ReservationRestFactory) {
		this.restClient = restClient;
	}

	@Action ensureRestClient() {
		if (!this.restClient)
			this.setRestClient(new ReservationRestFactory(this.reservationToken ?? undefined));
		return Promise.resolve(this.restClient as ReservationRestFactory);
	}

	@Action async syncFoodBeverageCategories(query: IOffersQuery & IOfferQueryDate) {
		this.setLoadingFoodBeverageCategories(true);
		const restClient = await this.ensureRestClient();
		const response = await restClient.foodBeverageCategories(query);
		this.setFoodBeverageCategories(response.data);
		this.setLoadingFoodBeverageCategories(false);
	}

	@Action async syncFoodBeverage(query: IOffersQuerySystem & IOfferQueryDate & IOfferQueryCategoryId & Partial<IOfferQueryPagination>) {
		this.setLoadingFoodBeverageItems({ CategoryId: query.categoryId, Loading: true });
		const restClient = await this.ensureRestClient();
		const response = await restClient.foodBeverage(query);
		this.setFoodBeverage({ CategoryId: query.categoryId, Items: response.data ?? [] });
		this.setLoadingFoodBeverageItems({ CategoryId: query.categoryId, Loading: false });
	}

	private mapModifiers(modifiersGroups: IModifierGroupBE[], originalId?: number) {
		const foodModifiers = modifiersGroups.filter(group => group.Modifiers && group.Modifiers.length > 0)
			.map(group => {
				let computedId = group.IdModifierGroup.toString();
				if (originalId) computedId += `-${originalId}`;
				const mappedGroup: IModifierGroup = {
					Title: group.Name,
					Id: group.IdModifierGroup.toString(),
					Rules: group.Rules,
					Modifiers: [],
					ComputedId: computedId
				};
				mappedGroup.Modifiers = group.Modifiers.map(mod => ({
					Id: mod.IdOriginal.toString(),
					Name: mod.Name,
					Price: mod.Price,
					GroupId: group.IdModifierGroup.toString(),
					...(originalId && { ReferencePriceKeyId: originalId })
				}));
				return mappedGroup;
			});
		return foodModifiers;
	}

	@Action async ensureItemFoodBeverageModifiers(pricekeyId: number) {
		if (this.foodBeverageModifierGroups.get(pricekeyId)) return;

		const restClient = await this.ensureRestClient();
		const response = await restClient.getItemFoodBeverageModifiers(this.systemId, pricekeyId);
		if (!response.data || response.data.ModifiersGroups.length === 0) return this.foodBeverageModifierGroups.clear();
		const modifiers = this.mapModifiers(response.data.ModifiersGroups);
		this.foodBeverageModifierGroups.set(pricekeyId, modifiers);
	}

	@Action async ensurePackageFoodBeverageModifiers(pricekeyId: number) {
		if (this.foodBeverageModifierGroups.get(pricekeyId)) return;

		const restClient = await this.ensureRestClient();
		const response = await restClient.getPackageFoodBeverageModifiers(this.systemId, pricekeyId);
		if (!response.data
			|| !response.data.PackageItems
			|| response.data.PackageItems.length === 0) return this.foodBeverageModifierGroups.clear();

		const foodModifiers = <IModifierGroup[]>[];
		response.data.PackageItems.forEach(pck => {
			if (pck.ModifiersGroups && pck.ModifiersGroups.length > 0) {
				const packMod = this.mapModifiers(pck.ModifiersGroups, pck.IdOriginal);
				foodModifiers.push(...packMod);
			}
		});
		this.foodBeverageModifierGroups.set(pricekeyId, foodModifiers);
	}

	@Action async syncFootwears(query: IOffersQuery & IOfferQueryDate & Partial<IOfferQueryPagination>) {
		this.setLoadingFootwears(true);
		const restClient = await this.ensureRestClient();
		const response = await restClient.footwears(query);
		this.setShoes(response.data?.Shoes ?? null);
		this.setSocks(response.data?.Socks ?? null);
		this.setLoadingFootwears(false);
	}

	@Action async syncShoesCategory() {
		this.setLoadingFootwears(true);
		const restClient = await this.ensureRestClient();
		const response = await restClient.getShoesCategory(this.systemId);
		this.setShoesCategory(response.data?.CategoriesShoesSizes ?? null);
		this.setLoadingFootwears(false);
	}

	@Action async syncExtras(query: IOffersQuery & IOfferQueryDate & Partial<IOfferQueryPagination>) {
		this.setLoadingExtras(true);
		const restClient = await this.ensureRestClient();
		const response = await restClient.extras(query);
		this.setExtras(response.data);
		this.setLoadingExtras(false);
	}

	@Action async reservationCreate() {
		if (!this.offer || !this.pickedDatetime) return;

		this.setLoadingReservationApi(true);
		const restClient = await this.ensureRestClient();
		const response = await restClient.reservationCreate(this.systemId, {
			DateFrom: this.pickedDatetime,
			WebOfferId: this.offer.OfferId,
			WebOfferTariffId: this.offer.ItemId,
			PlayersList: this.bowlers.map(b => ({
				TypeId: b.Id,
				Number: b.Quantity
			}))
		});
		if (response.error) {
			this.setReservationKey(null);
			this.updateReservationKeyExpire(0);
		} else if (response.data) {
			const token = response.fetchResponse?.headers?.get("x-sessiontoken") ?? null;
			if (!token)
				trackUserEvent(EventAI.ReservationTokenMissingOnCreate);

			this.setReservationToken(token);
			this.setReservationKey(response.data.ReservationKey);
			this.updateReservationKeyExpire(response.data.LifetimeMinutes);
		}

		this.setLoadingReservationApi(false);
		return response;
	}

	@Action async reservationTryRenew() {
		if (!this.reservationKey || this.loadingConfirmPaymentApi) return Promise.resolve(null);

		const CartStore = useCartStore();
		const reskey = String(this.reservationKey);
		const isBeforeNow = this.pickedDatetime && this.pickedDatetime <= new Date();
		const handleExit = async() => {
			useUserStore().reservationsHistoryRemove(reskey);
			this.setReservationKey(null);
			this.updateReservationKeyExpire(0);
			this.setLoadingReservationApi(false);
			await clearFluxPersistence();
		};
		if (isBeforeNow)
			return handleExit();
		this.setLoadingReservationApi(true);
		const restClient = await this.ensureRestClient();
		let response = await restClient.reservationRenew(this.systemId, reskey);
		let created = false;
		if (response.status >= 400) {
			let code = +response.status;
			if (code === 409 || code === 401) {
				handleExit();
				return response;
			}

			if (code === 404 && this.offer && this.pickedDatetime) {
				response = await restClient.reservationCreate(this.systemId, {
					DateFrom: this.pickedDatetime,
					WebOfferId: this.offer.OfferId,
					WebOfferTariffId: this.offer.ItemId,
					PlayersList: this.bowlers.map(b => ({
						TypeId: b.Id,
						Number: b.Quantity
					}))
				});
				code = +response.status;
				created = code < 400;
			}
			if (code >= 400) {
				handleExit();
				return response;
			}
		}
		if (created) {
			useUserStore().reservationsHistoryRemove(reskey);
			await clearFluxPersistence();
			this.setReservationStatus(null);
			this.setReservationNeedPayment(null);
			this.setPaymentStatus(null);
			CartStore.setIsPaid(false);
			const token = response.fetchResponse?.headers?.get("x-sessiontoken") ?? null;
			if (!token)
				trackUserEvent(EventAI.ReservationTokenMissingOnRenew);

			this.setReservationToken(token);
		}

		if (!response.data) return Promise.resolve(null);
		this.setReservationKey(response.data.ReservationKey);
		this.updateReservationKeyExpire(response.data.LifetimeMinutes);
		this.setLoadingReservationApi(false);
		await CartStore.handleCreatedAt(CartStore.createdAt); // << write current states on store

		return response;
	}

	@Action async getReservationStatus() {
		if (!this.reservationKey) return Promise.resolve();
		const restClient = await this.ensureRestClient();
		return restClient.reservationStatus(this.systemId, this.reservationKey);
	}

	@Action async reservationSetEndFlow() {
		if (!this.reservationKey) return Promise.resolve();
		const restClient = await this.ensureRestClient();
		return restClient.reservationSetEndFlow(this.systemId, this.reservationKey);
	}

	@Action async callRollBackPayment() {
		if (!this.reservationKey) return Promise.resolve();

		const restClient = await this.ensureRestClient();
		const response = restClient.rollback(this.systemId, this.reservationKey);
		if ((await response).fetchResponse?.ok)
			this.setOperationId(null);

		return response;
	}

	@Action async reservationDelete() {
		if (this.isReservationConfirmed || this.isReservationTemporary || !this.reservationKey) return;
		const restClient = await this.ensureRestClient();
		await restClient.reservationDelete(this.systemId, this.reservationKey);
		useUserStore().reservationsHistoryRemove(this.reservationKey);
	}

	// Tech Story 64265: [Bowler] Refactor reservationConfirm and reservationPay methods
	@Action async reservationPayAndFinalConfirm(response: IResponse<IReservationConfirmResponse, IResponseError<ReservationConfirmError>>): Promise<IReservationConfirmResult | PaymentError> {
		const systemStore = useSystemStore();

		if (!systemStore.isAsyncPaymentsFlowEnabled && response && response.error?.data?.Error?.Code === "ReservationAlreadyConfirmed") {
			this.setReservationStatus("CONFIRMED");
			this.setReservationNeedPayment(false);
			response.status = 200;
			return {
				paymentHappened: false,
				...response
			} as IReservationConfirmWithoutPaymentResult;
		}

		if ((response && response.status >= 400) || (response && !response.data)) {
			this.setOperationId(null);
			this.setReservationStatus(null);
			this.setPaymentStatus(null);
			return {
				paymentHappened: false,
				...response
			} as IReservationConfirmWithoutPaymentResult;
		}

		if (response.data?.NeedPayment && response.data?.ApprovePayment) {
			this.setOperationId(response.data?.OperationId ?? null);
			this.setReservationStatus(null);
			this.setPaymentStatus(null);
			const paymentProvider = systemStore.providerConfiguration?.ProviderName as PaymentProvider;
			const paymentData = response.data.ApprovePayment;
			const url = paymentData.MobileUrl ?? paymentData.Url;
			const target = providerRendersAs(paymentProvider) === "popup" ? "hpp-payments-button" : "hpp-payments-target";
			const paymentResult = await usePayments(paymentProvider, { Data: paymentData.Data, Method: paymentData.Method, Url: url }, target);
			if (paymentResult instanceof PaymentError)
				return paymentResult;

			return paymentResult as IReservationConfirmWithPaymentResult;
		}

		this.setOperationId(null);
		this.setPaymentStatus(null);
		this.setReservationStatus("CONFIRMED");
		const userStore = useUserStore();
		if (!userStore.isLoggedIn)
			userStore.clearPersistedData();

		return {
			paymentHappened: false,
			...response
		} as IReservationConfirmWithoutPaymentResult;
	}

	@Action async reservationConfirm(): Promise<IResponse<IReservationConfirmResponse, IResponseError<ReservationConfirmError>>> {
		if (!this.reservationKey)
			throw new Error("Reservation key is missing on reservationConfirm");

		const CartStore = useCartStore();
		if (!CartStore.summaryData)
			throw new Error("Cart summary data is missing on reservationConfirm");

		if (!CartStore.itemsDetails.length)
			throw new Error("Cart itemsDetails are missing on reservationConfirm");

		this.setLoadingReservationApi(true);
		const restClient = await this.ensureRestClient();
		let response: IResponse<IReservationConfirmResponse, IResponseError<ReservationConfirmError>> | null = null;
		const appStore = useAppStore();
		const userStore = useUserStore();
		const userData = userStore.userData;
		setupPaymentsEnv({
			environment: appStore.env.toLowerCase() as Lowercase<typeof appStore.env>,
			channel: appStore.channel.toLowerCase() as Lowercase<typeof appStore.channel>
		}, "mybowlingpassport.com");

		const ReturnUrl = getPaymentsAppUrl();
		const SummaryData = CartStore.summaryData;
		const Items = CartStore.itemsDetails;
		const Summary = {
			AddedTaxes: SummaryData.AddedTaxes,
			Deposit: SummaryData.Deposit,
			Fee: SummaryData.Fee,
			Total: SummaryData.Total,
			TotalItems: SummaryData.TotalItems,
			AutoGratuity: SummaryData.AutoGratuity,
			TotalWithoutTaxes: SummaryData.TotalWithoutTaxes
		};
		const Cart: IReservationConfirmData = {
			ReturnUrl,
			Items,
			Summary
		};

		if (userStore.isLoggedIn) {
			response = await restClient.reservationConfirm(
				this.systemId,
				this.reservationKey,
				Cart
			);
		} else if (userStore.isGuestWithData) {
			const GuestDetails = {
				Email: userData.Email,
				PhoneNumber: userData.Phone,
				ReferentName: userData.FirstName
			};
			response = await restClient.reservationGuestConfirm(
				this.systemId,
				this.reservationKey,
				{
					GuestDetails,
					Cart
				}
			);
		} else
			throw new Error("User is not logged in or is a guest without data");

		this.setLoadingReservationApi(false);
		this.setReservationNeedPayment(response?.data?.NeedPayment ?? null);
		return response;
	}

	@Action async getPayPalTransactionIdentifiers(): Promise<TransactionIdentifiers | null> {
		const CartStore = useCartStore();
		if (!this.reservationKey || !CartStore.summaryData) return null;

		this.setLoadingReservationApi(true);
		const restClient = await this.ensureRestClient();
		let response: IResponse<IReservationConfirmResponse, IResponseError<ReservationConfirmError>> | null = null;
		const userStore = useUserStore();
		const userData = userStore.userData;
		const SummaryData = CartStore.summaryData;
		const { path } = getPaymentPopup();
		const ReturnUrl = new URL(path, location.origin).href;
		const Items = CartStore.itemsDetails;
		const Summary = {
			AddedTaxes: SummaryData.AddedTaxes,
			Deposit: SummaryData.Deposit,
			Fee: SummaryData.Fee,
			Total: SummaryData.Total,
			TotalItems: SummaryData.TotalItems,
			AutoGratuity: SummaryData.AutoGratuity,
			TotalWithoutTaxes: SummaryData.TotalWithoutTaxes
		};
		const Cart: IReservationConfirmData = {
			ReturnUrl,
			Items,
			Summary
		};

		if (userStore.isLoggedIn) {
			response = await restClient.reservationConfirm(
				this.systemId,
				this.reservationKey,
				Cart
			);
		} else if (userStore.isGuestWithData) {
			const GuestDetails = {
				Email: userData.Email,
				PhoneNumber: userData.Phone,
				ReferentName: userData.FirstName
			};
			response = await restClient.reservationGuestConfirm(
				this.systemId,
				this.reservationKey,
				{
					GuestDetails,
					Cart
				}
			);
		} else
			throw new Error("User is not logged in or is a guest without data");

		this.setLoadingReservationApi(false);
		if (!response) return null;

		const systemStore = useSystemStore();
		if (!systemStore.isAsyncPaymentsFlowEnabled && response && response.error?.data?.Error?.Code === "ReservationAlreadyConfirmed") {
			this.setReservationStatus("CONFIRMED");
			this.setReservationNeedPayment(false);
			return null;
		} else if ((response && response.status >= 400) || (response && !response.data)) {
			this.setReservationStatus(null);
			return null;
		} else {
			this.setReservationNeedPayment(response?.data?.NeedPayment ?? null);
			if (response.data?.NeedPayment && response.data?.ApprovePayment) {
				this.setReservationStatus(null);

				const isPaypalPayload = (data: any): data is TransactionIdentifiers =>
					data?.OperationId && data?.OrderId;

				if (isPaypalPayload(response.data.ApprovePayment.Data)) {
					this.setOperationId(response.data.ApprovePayment.Data?.OperationId ?? null);
					return response.data.ApprovePayment.Data;
				} else return null;
			}

			return null;
		}
	}

	@Action async syncReservationStatus(systemId: number) {
		if (!this.reservationKey || !this.operationId) return null;
		const restClient = await this.ensureRestClient();
		const response = await restClient.getReservationStatus(systemId, this.reservationKey, this.operationId);

		if (response?.data) {
			this.setReservationStatus(response.data.ReservationStatus);
			this.setPaymentStatus(response.data.PaymentStatus);
		}
	}

	@Action async ensureOfferOptions(systemId: number) {
		if (!this.reservationKey || !this.offer || this.options) return null;
		const restClient = await this.ensureRestClient();

		const response = await restClient.getWebOfferOptions(systemId, this.offer.OfferId);
		this.setOptions(response.data);
	}

	@Action async saveBowlers({ systemId, Players }: {systemId: number, Players: IReservationBowlerOptions[]}) {
		if (!this.reservationKey) return null;
		const restClient = await this.ensureRestClient();
		return restClient.saveBowlersOptions(systemId, this.reservationKey, Players);
	}

	@Action prepareMandatoryPage() {
		if (!this.options) return this.setMandatoryPages(null);

		const checkIfMandatoryPageIsEnabled = (condition: boolean, ...elements) => {
			return condition ? elements : [];
		};

		const mandatoryPagesEnable: IMandatoryPage[] = [
			...checkIfMandatoryPageIsEnabled(this.options.IsShoesEnabled, {
				name: footwearsPage,
				visited: false
			}),
			...checkIfMandatoryPageIsEnabled(this.options.ShowGamesAndExtraPage, {
				name: extrasPage,
				visited: false
			}),
			...checkIfMandatoryPageIsEnabled(this.options.ShowFoodAndBeveragePage, {
				name: foodBeveragePage,
				visited: false
			})
		];

		return this.setMandatoryPages(mandatoryPagesEnable);
	}

	@Action ensureMandatoryPages() {
		if (!this.reservationKey || !this.offer || this.mandatoryPages) return null;
		this.prepareMandatoryPage();
	}

	private async _syncWithStore(context: IBaseContext) {
		await UserModuleInit;

		const moduleKey = await persistenceKey();
		if (!moduleKey) return this.setPersistenceReady();
		const accessCtx = context.access();
		const [result] = await resolveMutationContext(accessCtx.get<IReservationData>(moduleKey));
		const data: IReservationData = result ?? {
			bowlers: [] as IPlayersQuantity[],
			bowlersTotal: 0,
			shoesTotal: 0,
			latestView: null,
			pickedDatetime: null,
			reservationStatus: null,
			reservationNeedPayment: null,
			reservationKey: null,
			reservationToken: null,
			reservationKeyExpire: null,
			paymentStatus: null,
			systemId: useSystemStore().Id,
			offer: null
		};
		this.setData(data);
		this.setPersistenceReady();
	}

	@Action initModuleData(context: IBaseContext) {
		return this._syncWithStore(context);
	}

	@Action syncWithStore() {
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		const ctx = getMutationContext(moduleName);
		if (!ctx) return Promise.resolve();
		return this._syncWithStore(ctx);
	}
}
const moduleName = "reservation";
let ReservationStore: ReservationStoreFactory | null;
function useReservationStore() {
	if (ReservationStore) return ReservationStore;

	const mod = ReservationStore = new ReservationStoreFactory({ store, name: moduleName });

	return mod;
}
export const ReservationModuleInit = usePersistentModule(moduleName, useReservationStore(), {
	async onMutation(context) {
		const ReservationStore = useReservationStore();

		if (!context || !ReservationStore.persistenceInitialized) return;
		if (ReservationStore.isReservationConfirmed || ReservationStore.isReservationTemporary) return;

		const moduleKey = await persistenceKey();
		if (!moduleKey) return;

		const accessCtx = context.access();

		if (context.mutationsContains("clearData") || context.mutationsContains("clearConfirmedData"))
			return await resolveMutationContext(accessCtx.delete(moduleKey));

		if (!ReservationStore.hasReservationFormData)
			return;

		if (!context.mutationsContains("ensureRestClient") && !context.mutationsContains("setSummaryData") && !context.mutationsContains("cacheOpeningDay"))
			await resolveMutationContext(accessCtx.put(ReservationStore.data, moduleKey));
	}
});

export default useReservationStore;
