import { IPolicyTermMenu, PolicyTermType } from "@app-rest/common.interfaces";
import SystemsRestFactory, { PageType } from "@app-rest/systems";
import { IFooterSection, IFunctionalities, IGenericPage, INavigationBar, IOpeningDate, IOpeningTimes, IPlayersTypes, ISystemConfigurations, ISystemCurrency, ISystemInfo, IThankYouPage, PaypalProviderConfiguration, ProviderConfiguration } from "@app-rest/systems.interfaces";
import { dateKey } from "@app-utilities/dates";
import { SystemConfigurationName } from "@app-utilities/interfaces";
import { addDays, addMonths, endOfDay, endOfMonth, startOfDay } from "date-fns";
import { Action, Module, Mutation, VuexModule } from "vuex-class-modules";

import store from ".";

export interface IOpeningDatesRequest {
	from: Date,
	to: Date
}
export interface ISystemTemplateCommonParts {
	LogoUrl?: string
	BigImageUrl?: string
	BigImageCaption?: string
	Body?: string
	FooterCopy?: string
	Footers?: IFooterSection[]
}
const openingsCache = new Map<string, IOpeningDate>(); // <<  key: dateKey(), value: IOpeningDate
@Module class SystemStoreFactory extends VuexModule {
	info: ISystemInfo | null = null;
	functionalities: IFunctionalities | null = null;
	configurations: ISystemConfigurations | null = null;
	templateCommonParts: ISystemTemplateCommonParts | null = null;
	navigationBar: INavigationBar[] = [];
	currency: ISystemCurrency | null = null;
	timezone: number | null = null;
	lastBookableDay: string | null = null;
	openingTimes: IOpeningTimes | null = null;
	openingFirst = new Date();
	openingLast = new Date();
	playerTypes: IPlayersTypes | null = null;
	restClient: SystemsRestFactory | null = null;
	loadingOpenings = false;
	loadingCurrency = false;
	pages: Partial<Record<PageType, IGenericPage>> = {};
	providerConfiguration: ProviderConfiguration | null = null;
	terms: IPolicyTermMenu[] = [];
	maintenanceMode = false;

	get Id() {
		return this.info?.Id ?? 0;
	}

	get Name() {
		return this.info?.Name ?? "";
	}

	get CompanyId() {
		return this.info?.CompanyId ?? 0;
	}

	get canManageFoodAndBeverageModifiersEnabled() {
		return this.functionalities?.ManageFoodAndBeverageModifiers
			&& this.functionalities?.ManageFoodAndBeverageModifiers?.Enabled;
	}

	get isGuestReservationEnabled() {
		return this.functionalities?.GuestReservations?.Enabled && this.configurations?.Configurations?.IsGuestReservationEnabled;
	}

	get isAsyncPaymentsFlowEnabled() {
		return this.functionalities?.["TestAsynchronousPayments-preview"]?.Enabled;
	}

	get getOpeningDay() {
		return (datestring: string) => openingsCache.get(datestring);
	}

	get getNextOpenDay() {
		return (from: Date = new Date()) => {
			if (this.isOutOfBoundaries(from))
				return;
			let cursor = startOfDay(new Date(from));
			while (cursor <= this.openingLast) {
				const cursorFmt = dateKey(cursor);
				const opening = this.getOpeningDay(cursorFmt);
				if (opening?.IsOpen)
					return opening;

				cursor = addDays(cursor, 1);
			}
		};
	}

	get isOutOfBoundaries() {
		return (date: Date) => date < this.openingFirst || date > this.openingLast;
	}

	get activePlayerTypes() {
		return this.playerTypes?.Types.filter(t => t.Active === true);
	}

	get hoursDayChangeTime() {
		if (!this.info || !this.info.DayChangeTime) return;

		const timeSep = ":";
		return +(this.info.DayChangeTime.split(timeSep)[0]);
	}

	get minutesDayChangeTime() {
		if (!this.info || !this.info.DayChangeTime) return;

		const timeSep = ":";
		return +(this.info.DayChangeTime.split(timeSep)[1]);
	}

	get isProviderPaypal() {
		return this.providerConfiguration?.ProviderName === "Paypal";
	}

	get paypalClientId() {
		if (this.providerConfiguration?.ProviderName === "Paypal")
			return (this.providerConfiguration as PaypalProviderConfiguration).Data.ClientId;
		else
			return null;
	}

	get isMaintenanceModeActive() {
		return this.maintenanceMode;
	}

	@Mutation clearData() {
		this.info = null;
		this.timezone = null;
		this.openingFirst = new Date();
		this.openingLast = new Date();
		this.currency = null;
		this.playerTypes = null;
		this.lastBookableDay = null;
		this.pages = {};
		this.providerConfiguration = null;
		openingsCache.clear();
	}

	@Mutation setInfo(info: ISystemInfo | null) {
		this.info = info;
	}

	@Mutation setFunctionalities(functionalities: IFunctionalities | null) {
		this.functionalities = functionalities;
	}

	@Mutation setConfigurations(configurations: ISystemConfigurations | null) {
		this.configurations = configurations;
	}

	@Mutation setTemplateCommonParts(template: Partial<ISystemTemplateCommonParts>) {
		const parts = { ...this.templateCommonParts, ...template };
		this.templateCommonParts = parts;
	}

	@Mutation setTimezone(timezone: number | null) {
		this.timezone = timezone;
	}

	@Mutation setLastBookableDay(datestring: string | null) {
		this.lastBookableDay = datestring;
	}

	@Mutation setOpeningTimes(data: IOpeningTimes | null) {
		this.openingTimes = data;
	}

	@Mutation setOpeningFirst(date: Date) {
		this.openingFirst = date;
	}

	@Mutation setOpeningLast(date: Date) {
		this.openingLast = date;
	}

	@Mutation setOpeningDays(days: IOpeningDate[]) {
		days.forEach(day => openingsCache.set(day.Date, day));
	}

	@Mutation setPlayerTypes(types: IPlayersTypes | null) {
		this.playerTypes = types;
	}

	@Mutation setLoadingOpenings(state: boolean) {
		this.loadingOpenings = state;
	}

	@Mutation setLoadingCurrency(state: boolean) {
		this.loadingCurrency = state;
	}

	@Mutation setCurrency(currency: ISystemCurrency | null) {
		this.currency = currency;
	}

	@Mutation setNavigationBar(navigationBar: INavigationBar[]) {
		this.navigationBar = navigationBar;
	}

	@Mutation setPage(data: { page: IGenericPage | IThankYouPage, type: PageType }) {
		this.pages[data.type] = data.page;
	}

	@Mutation setProviderConfiguration(data: ProviderConfiguration) {
		this.providerConfiguration = data;
	}

	@Mutation setTerms(data: IPolicyTermMenu[]) {
		this.terms = data;
	}

	@Mutation setMaintenanceMode(status: boolean) {
		this.maintenanceMode = status;
	}

	@Mutation setRestClient(restClient: SystemsRestFactory) {
		this.restClient = restClient;
	}

	@Action ensureRestClient() {
		if (!this.restClient)
			this.setRestClient(new SystemsRestFactory());
		return Promise.resolve(this.restClient as SystemsRestFactory);
	}

	@Action async ensureInfo(systemId: number) {
		if (this.info && this.info.Id === systemId) return;
		const restClient = await this.ensureRestClient();
		const response = await restClient.info(systemId);
		this.setInfo(response.data);
	}

	@Action async ensureFunctionalities(systemId: number) {
		if (this.info && this.info.Id === systemId && this.functionalities) return;
		const restClient = await this.ensureRestClient();
		const response = await restClient.functionalities(systemId);
		this.setFunctionalities(response.data);
	}

	@Action async ensureConfigurations() {
		if (!this.info?.Id || this.configurations) return;
		const restClient = await this.ensureRestClient();
		const configurations: SystemConfigurationName[] = ["IsGuestReservationEnabled"];
		const response = await restClient.configurations(this.info.Id, configurations);
		this.setConfigurations(response.data ?? null);
	}

	@Action async syncOpenings({ from, to }: IOpeningDatesRequest) {
		if (!this.info?.Id) return;
		this.setLoadingOpenings(true);
		from = startOfDay(from);
		to = endOfDay(to);

		if (from < this.openingFirst) this.setOpeningFirst(from);

		if (to > this.openingLast) this.setOpeningLast(to);

		await this.syncOpeningTimes({ from, to });
		this.setTimezone(this.openingTimes?.TimeZone as number ?? null);
		this.setLastBookableDay(this.openingTimes?.LastBookableDay ?? null);

		if (this.openingTimes)
			this.setOpeningDays(this.openingTimes.Dates);
		this.setLoadingOpenings(false);
	}

	@Action async ensureOpenings(from: Date) {
		const isOut = this.isOutOfBoundaries(from);
		if (isOut || !this.openingTimes) {
			const to = endOfMonth(addMonths(from, 3));
			await this.syncOpenings({ from, to });
		}
	}

	@Action async ensurePlayerTypes() {
		if (!this.info?.Id || this.playerTypes) return;

		const restClient = await this.ensureRestClient();
		const response = await restClient.playerTypes(this.info.Id);
		this.setPlayerTypes(response.data ?? null);
	}

	@Action async ensureCurrency() {
		if (this.loadingCurrency || this.currency) return;
		this.setLoadingCurrency(true);
		if (this.info?.Id) {
			const restClient = await this.ensureRestClient();
			const response = await restClient.mainCurrency(this.info.Id);
			this.setCurrency(response.data);
		}
		this.setLoadingCurrency(false);
	}

	@Action async syncOpeningTimes({ from, to }: IOpeningDatesRequest) {
		if (!this.info?.Id) return;
		const restClient = await this.ensureRestClient();
		const response = await restClient.openingTimes(this.info.Id, from, to);
		this.setOpeningTimes(response.data ?? null);
	}

	@Action async ensureGenericPage({ systemId, pageType }: { systemId: number, pageType: PageType }) {
		if (this.pages[pageType]) return;

		const restClient = await this.ensureRestClient();
		const response = await restClient.templateGenericPage(systemId, pageType);
		if (response.data)
			this.setPage({ page: response.data, type: pageType });
	}

	@Action async ensureTemplateCommonParts() {
		if (!this.info?.Id || this.templateCommonParts?.LogoUrl) return;

		const restClient = await this.ensureRestClient();
		const response = await restClient.templateCommonParts(this.info.Id);
		if (response.data)
			this.setTemplateCommonParts(response.data);
	}

	@Action async ensureTemplateHomePage() {
		if (!this.info?.Id || this.templateCommonParts?.Body) return;

		const restClient = await this.ensureRestClient();
		const response = await restClient.templateHomePage(this.info.Id);
		if (response.data)
			this.setTemplateCommonParts(response.data);
	}

	@Action async ensureTemplateReservation() {
		if (!this.info?.Id || (this.pages["RESERVATION ON HOLD"] && this.pages["RESERVATION SUBMITTED"] && this.pages["RESERVATION CONFIRMED"])) return;

		const restClient = await this.ensureRestClient();
		const response = await restClient.templateReservationPage(this.info.Id);
		if (response.data) {
			this.setPage({ page: response.data.ReservationOnHold, type: "RESERVATION ON HOLD" });
			this.setPage({ page: response.data.ReservationSubmitted, type: "RESERVATION SUBMITTED" });
			this.setPage({ page: response.data.ReservationConfirmed, type: "RESERVATION CONFIRMED" });
		}
	}

	@Action async ensureTemplateThankYou() {
		if (!this.info?.Id || (this.pages["THANK YOU ON HOLD"] && this.pages["THANK YOU SUBMITTED"] && this.pages["THANK YOU CONFIRMED"])) return;

		const restClient = await this.ensureRestClient();
		const response = await restClient.templateThankYouPage(this.info.Id);
		if (response.data) {
			this.setPage({ page: response.data.ReservationOnHold, type: "THANK YOU ON HOLD" });
			this.setPage({ page: response.data.ReservationSubmitted, type: "THANK YOU SUBMITTED" });
			this.setPage({ page: response.data.ReservationConfirmed, type: "THANK YOU CONFIRMED" });
		}
	}

	@Action async syncTemplateNavigationBar() {
		if (!this.info?.Id || this.navigationBar.length) return;

		const restClient = await this.ensureRestClient();
		const response = await restClient.templateNavigationBar(this.info.Id);
		this.setNavigationBar(response?.data ?? []);
	}

	@Action async ensureProviderConfiguration() {
		if (!this.info?.Id || this.providerConfiguration) return;
		const restClient = await this.ensureRestClient();
		const response = await restClient.getProviderConfiguration(this.info.Id);
		if (response?.status === 404 && response.error?.data?.Error?.Code === "BowlerProviderSetupNotFound") {
			this.setProviderConfiguration({
				ProviderName: "None"
			});
		}
		if (response?.data) this.setProviderConfiguration(response.data);
	}

	@Action async syncTerms() {
		if (!this.info?.Id || this.terms.length) return;

		const restClient = await this.ensureRestClient();
		const response = await restClient.getTermsList(this.info.Id);
		const onlyBowlerTerms = response?.data?.TermsSetupList.filter(term => term.Type !== PolicyTermType.KioskPlayNow);
		this.setTerms(onlyBowlerTerms ?? []);
	}

	@Action async syncMaintenanceModeActive() {
		if (!this.info?.Id) return;
		const restClient = await this.ensureRestClient();
		const response = await restClient.getMaintenanceModeStatus(this.info.Id);
		if (!response.error && response.data != null) this.setMaintenanceMode(response.data);
	}
}
const moduleName = "system";
let SystemStore: SystemStoreFactory | null;
function useSystemStore() {
	if (SystemStore) return SystemStore;

	const mod = SystemStore = new SystemStoreFactory({ store, name: moduleName });

	return mod;
}

export default useSystemStore;
